import React from "react";

import {GetServerSidePropsResult, NextPage} from "next";

import {Container} from "@chakra-ui/react";

const Index: NextPage = () => (
  <Container minWidth="100%" w="100vw" overflow="hidden" h="100vh">
    <iframe src="https://www.eventsframe.com/" style={{width: "100%", height: "100%"}}></iframe>
  </Container>
);
// export async function getServerSideProps(): Promise<GetServerSidePropsResult<undefined>> {
//   return {
//     redirect: {
//       permanent: false,
//       destination: `/my-wallet/`,
//     },
//   };
// }

export default Index;
